<template>
    <a-space direction="horizontal" :size="8" style="margin-bottom: 15px">
        <a-input v-model:value="code" placeholder="请输入产品编码以检索" />
        <a-button type="primary" @click="getTableData">搜索</a-button>
    </a-space>
    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="pagination"
        @change="paginationChange"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
            </template>
            <template v-if="column.dataIndex === 'statusDesc'">
                <span :style="{ color: getStatusColor(record?.status) }">
                    {{ record?.statusDesc }}
                </span>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { fileURL } from "@/utils/config";
import { apiCjFinishProduct } from "@/api";
import { getStatusColor } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "条码",
        dataIndex: "barcode",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "生产状态",
        dataIndex: "statusDesc",
    },
    {
        title: "完成操作人",
        dataIndex: "completeOperatorDisplayName",
    },
];
export default defineComponent({
    props: {
        cjWorkOrderId: {
            default: "",
        },
    },
    setup(props) {
        const state = reactive({
            code: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
            tableData: [],
        });

        const getTableData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    cjWorkOrderId: props.cjWorkOrderId,
                },
            };
            let res = await apiCjFinishProduct(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList;
            }
        };

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTableData();
        };

        watch(
            () => props.cjWorkOrderId,
            newVal => {
                newVal && getTableData();
            },
            {
                immediate: true,
            }
        );

        return {
            columns,
            ...toRefs(state),
            getTableData,
            fileURL,
            paginationChange,
            getStatusColor,
        };
    },
});
</script>
