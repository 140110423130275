<template>
    <a-table
        :columns="columns"
        :data-source="data.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "操作内容",
        dataIndex: "operation",
    },
    {
        title: "操作人",
        dataIndex: "userDisplayName",
    },
    {
        title: "操作时间",
        dataIndex: "createdTime",
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
        routeInfo: {
            default: null,
        },
    },
    setup() {
        const state = reactive({
            previewId: null,
        });

        return {
            columns,
            ...toRefs(state),
        };
    },
});
</script>
