<template>
    <ProductList v-model:productListVisible="productListVisible" @onSelect="onSelectProduct" />
    <OrderList
        v-model:orderListModalVisible="orderListModalVisible"
        orderType="WORKSHOP"
        @select="onSelectOrder"
    />
    <WorkorderCategory
        :data="workorderCategoryOptions"
        typeName="工单"
        v-model:categoryVisible="workorderCategoryVisible"
        @onSelect="onChangeWorkorderCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}工单`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="生产订单号"
                name="orderId"
                :rules="[{ required: !formData.notRelatedOrder, message: '请选择生产订单号' }]"
            >
                <div class="contant-item">
                    <SelectInput
                        :title="produceOrderCode"
                        :onClick="handleOpenOrderList"
                        :disabled="formData.notRelatedOrder || Boolean(upDateId)"
                        :showDeleteIcon="false"
                        style="width: 320px; top: -5px"
                    ></SelectInput>
                    <a-checkbox
                        v-model:checked="formData.notRelatedOrder"
                        :disabled="Boolean(upDateId)"
                    >
                        不关联订单
                    </a-checkbox>
                </div>
                <div v-if="relatedOrderInfo" style="margin-top: 8px">
                    <span>生产数量：{{ relatedOrderInfo?.quantity }}</span>
                    <span style="margin-left: 8px">
                        已下发数量：{{ relatedOrderInfo?.orderStartProductCount }}
                    </span>
                </div>
            </a-form-item>

            <a-form-item label="工单号" name="code">
                <a-input
                    v-model:value="formData.code"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item label="工单类别" name="categoryName">
                <SelectInput
                    :title="formData.categoryName"
                    :onClick="() => (workorderCategoryVisible = true)"
                    @onDelete="() => onChangeWorkorderCategory()"
                ></SelectInput>
            </a-form-item>

            <a-form-item
                label="工单类型"
                name="type"
                :rules="[{ required: formData.notRelatedOrder, message: '请选择工单类型' }]"
            >
                <Select
                    v-model:value="formData.type"
                    :options="typeOptions"
                    labelKey="valueZh"
                    :disabled="!formData.notRelatedOrder"
                />
            </a-form-item>

            <a-form-item
                label="产品编码"
                name="productCode"
                :rules="[{ required: true, message: '请选择产品' }]"
            >
                <a-form-item>
                    <div class="contant-item">
                        <a-input
                            style="width: 330px"
                            v-model:value="formData.productCode"
                            :disabled="true"
                            :maxlength="100"
                            showCount
                        />
                        <a-button
                            :disabled="!formData.notRelatedOrder"
                            type="primary"
                            @click="() => (productListVisible = true)"
                        >
                            查询
                        </a-button>
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="产品名称"
                name="productName"
                :rules="[{ required: true, message: '请选择产品' }]"
            >
                <a-input
                    v-model:value="formData.productName"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="单位"
                name="taskQuantityUnit"
                :disabled="true"
                :rules="[{ required: true, message: '请输入单位' }, { validator: checkSpaceAll }]"
            >
                <a-input
                    v-model:value="formData.taskQuantityUnit"
                    :disabled="!formData.notRelatedOrder"
                />
            </a-form-item>

            <a-form-item label="规格型号" name="spec">
                <a-input
                    v-model:value="formData.spec"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="生产批次号"
                name="productionBatchNo"
                :rules="[
                    { required: true, message: '请输入生产批次号' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-input v-model:value="formData.productionBatchNo" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="任务数量"
                name="taskQuantity"
                :rules="[{ required: true, message: '请输入任务数量' }]"
            >
                <a-input-number
                    v-model:value="formData.taskQuantity"
                    :min="0"
                    :max="comMaxTaskQuantity"
                    :step="1"
                    :precision="0"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="预计投产日期" name="scheduledStartDate">
                <a-date-picker
                    v-model:value="formData.scheduledStartDate"
                    valueFormat="YYYY-MM-DD"
                    :disabled-date="disabledStartDate"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="预计完成日期" name="scheduledEndDate">
                <a-date-picker
                    v-model:value="formData.scheduledEndDate"
                    valueFormat="YYYY-MM-DD"
                    :disabled-date="disabledEndDate"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="备注" name="remarks" :rules="[{ validator: checkSpaceAll }]">
                <a-textarea
                    v-model:value="formData.remarks"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiCJWorkOrderGetUpdateData, apiCJWorkOrderSavaUpdateData } from "@/api";
import Select from "@/components/Select";
import OrderList from "@/components/OrderList";
import ProductList from "@/components/ProductList";
import WorkorderCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import SelectInput from "@/components/SelectInput";
import dayjs from "dayjs";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk", "update:upDateId"],
    components: {
        Select,
        OrderList,
        WorkorderCategory,
        DownOutlined,
        ProductList,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            orderListModalVisible: false,
            productListVisible: false,
            workorderCategoryVisible: false,
            workorderCategoryOptions: [],
            typeOptions: [],
            produceOrderCode: "",
            relatedOrderInfo: null,
            comMaxTaskQuantity: computed(() => {
                const { relatedOrderInfo } = state;
                if (relatedOrderInfo) {
                    const { quantity, orderStartProductCount } = relatedOrderInfo;
                    return quantity - orderStartProductCount;
                } else {
                    return Infinity;
                }
            }),
            formData: {
                id: "",
                code: "",
                orderId: "",
                notRelatedOrder: false,
                type: "",
                projectName: "",
                productName: "",
                productCode: "",
                categoryName: "",
                categoryCode: "",
                spec: "",
                unit: "",
                productionBatchNo: "",
                taskQuantity: "",
                taskQuantityUnit: "",
                scheduledStartDate: "",
                scheduledEndDate: "",
                remarks: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onSelectOrder = order => {
            state.relatedOrderInfo = order;
            state.formData.orderId = order?.id;
            state.produceOrderCode = order?.produceOrderCode;
            state.formData.productName = order?.productName;
            state.formData.spec = order?.spec;
            state.formData.productCode = order?.productCode;
            state.formData.taskQuantityUnit = order?.unit;
        };

        const onFinish = async () => {
            let res = await apiCJWorkOrderSavaUpdateData(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiCJWorkOrderGetUpdateData(id);
            if (res.status === "SUCCESS") {
                let { workOrder, categoryCandidate, typeCandidate } = res.data;
                state.workorderCategoryOptions = categoryCandidate;
                state.produceOrderCode = workOrder?.orderCode;
                state.typeOptions = typeCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = workOrder ? workOrder[key] : "";
                });
                state.formData.notRelatedOrder = workOrder ? !Boolean(workOrder?.orderId) : false;
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectProduct = ([product]) => {
            state.formData.productCode = product?.code;
            state.formData.productName = product?.name;
            state.formData.spec = product?.spec;
            state.formData.taskQuantityUnit = product?.primaryUom;
        };

        const disabledStartDate = current => {
            const { scheduledEndDate } = state.formData;
            if (!scheduledEndDate) {
                return false;
            }
            return current && current >= dayjs(scheduledEndDate).endOf("day");
        };

        const disabledEndDate = current => {
            const { scheduledStartDate } = state.formData;
            if (!scheduledStartDate) {
                return false;
            }
            return current && current < dayjs(scheduledStartDate).startOf("day");
        };

        const handleOpenOrderList = () => {
            if (props.upDateId) return;
            if (!state.formData.notRelatedOrder) {
                state.orderListModalVisible = true;
            }
        };

        const onChangeWorkorderCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const clearRelatedOrderInfo = () => {
            state.relatedOrderInfo = null;
            state.formData.orderId = "";
            state.produceOrderCode = "";
            state.formData.productName = "";
            state.formData.productCode = "";
            state.formData.spec = "";
            state.formData.taskQuantityUnit = "";
            state.formData.taskQuantity = "";
        };

        watch(
            () => state.formData.notRelatedOrder,
            newVal => {
                if (!newVal) {
                    state.formData.type = "";
                }
                if (!props.upDateId) {
                    clearRelatedOrderInfo();
                }
            }
        );

        watch(
            () => props.upDateId,
            async newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    await getIndexData(newVal);
                    clearRelatedOrderInfo();
                    if (!newVal) {
                        state.formData.code = await getAutoCreateCode("MES_CJ_WORK_ORDER");
                    }
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
            onSelectOrder,
            disabledStartDate,
            disabledEndDate,
            handleOpenOrderList,
            checkSpaceAll,
            onChangeWorkorderCategory,
            onSelectProduct,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: 999;
}
</style>
