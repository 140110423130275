<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="eventUpdateOk" />
        <DetailDrawer v-model:detailId="detailId" :tabStatus="status" />
        <IssueDrawer v-model:issueId="issueId" @onEmitOk="getTabelData" />
        <Produce v-model:produceId="produceId" @onEmitOk="getTabelData" />
        <a-tabs v-model:activeKey="status" @change="onChangeTab">
            <a-tab-pane
                v-for="item in tabs"
                :key="item.code"
                :tab="item.name + '工单'"
            ></a-tab-pane>
        </a-tabs>
        <FilterBlock @search="search" :produceTeamOptions="produceTeamOptions" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'orderCode'">
                        {{ record?.orderCode || "未关联订单号" }}
                    </template>
                    <template v-if="column.dataIndex === 'progressPercent'">
                        <a-progress :percent="record?.progressPercent" size="small" />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a-space
                                v-show="status == (tabs && tabs[0].code)"
                                direction="horizontal"
                                :size="5"
                            >
                                <a
                                    v-perm="'btn:mes-workshop-work-order-producing'"
                                    @click="() => (produceId = record.id)"
                                >
                                    投产
                                </a>
                                <a
                                    v-perm="'btn:mes-workshop-work-order-edit'"
                                    @click="() => (upDateId = record.id)"
                                >
                                    编辑
                                </a>
                                <a
                                    v-perm="'btn:mes-workshop-work-order-delete'"
                                    @click="handleDelete(record.id)"
                                >
                                    删除
                                </a>
                            </a-space>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            v-perm="'btn:mes-workshop-work-order-add'"
                            type="primary"
                            @click="() => (upDateId = '')"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import DetailDrawer from "./detailDrawer";
import IssueDrawer from "./issueDrawer";
import Produce from "./produce";
import { confirmModal, showMessage } from "@/utils/common";
import { apiCJWorkOrderIndexData, apiCJWorkOrderList, apiCJWorkOrderDelete } from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        DetailDrawer,
        IssueDrawer,
        Produce,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "工单号",
                dataIndex: "code",
            },
            {
                title: "生产订单号",
                dataIndex: "orderCode",
            },
            {
                title: "工单类型",
                dataIndex: "typeDesc",
            },
            {
                title: "项目名称",
                dataIndex: "projectName",
            },
            {
                title: "项目编码",
                dataIndex: "projectCode",
            },
            {
                title: "工单进度",
                dataIndex: "progressPercent",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
                width: 100,
            },
            {
                title: "规格型号",
                dataIndex: "spec",
            },
            {
                title: "任务数量",
                dataIndex: "taskQuantity",
            },
            {
                title: "单位",
                dataIndex: "taskQuantityUnit",
            },
            {
                title: "批次号",
                dataIndex: "productionBatchNo",
            },
            {
                title: "生产班组",
                dataIndex: "produceTeamName",
            },
            {
                title: "工艺路线",
                dataIndex: "processName",
            },
            {
                title: "计划投产日期",
                dataIndex: "scheduledStartDate",
            },
            {
                title: "计划完工日期",
                dataIndex: "scheduledEndDate",
            },
            {
                title: "提交时间",
                dataIndex: "createdTime",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 180,
            },
        ];

        const state = reactive({
            status: "",
            tabs: [],
            produceTeamOptions: [],
            upDateId: null,
            detailId: null,
            issueId: null,
            produceId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCJWorkOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const onChangeTab = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const handleDelete = id => {
            confirmModal({
                type: "warning",
                content: "工单删除后将无法恢复，是否确认删除该工单？",
                onOk: async () => {
                    let res = await apiCJWorkOrderDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const getIndexData = async () => {
            let res = await apiCJWorkOrderIndexData("");
            if (res.status === "SUCCESS") {
                state.produceTeamOptions = res.data?.produceTeamCandidate;
                state.tabs = res.data?.statusCandidate;
                if (state.tabs?.length) {
                    state.status = state.tabs[0]?.code;
                    getTabelData();
                }
            }
        };
        getIndexData();

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            eventUpdateOk,
            onChangeTab,
            handleOpenDetail,
            handleDelete,
            getTabelData,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    //display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
