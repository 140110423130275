<template>
    <Teams v-model:teamsVisible="teamsVisible" @select="onSelectedTeam" />
    <a-descriptions title="班组信息" :column="2">
        <template #extra>
            <a-button type="primary" @click="() => (teamsVisible = true)">选择</a-button>
        </template>
        <a-descriptions-item label="班组名称">{{ teamInfo?.name }}</a-descriptions-item>
        <a-descriptions-item label="班组长">{{ teamInfo?.leaderDisplayName }}</a-descriptions-item>
        <a-descriptions-item label="班组人数">
            {{ tableData?.length ? tableData?.length : "" }}
        </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="成员列表"></a-descriptions>
    <a-table :columns="columns" :data-source="tableData" bordered size="small">
        <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'jobNo'">
                {{ record?.sysUser?.jobNo }}
            </template>
            <template v-if="column.dataIndex === 'dept-post'">
                <span v-for="item in record?.sysUser?.deptInfos">
                    {{ item?.sysDept?.name }} - {{ item?.sysPost?.name }}
                </span>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
import { apiTeamGetInfo } from "@/api";
import Teams from "./teams";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 50,
    },
    {
        title: "工号",
        dataIndex: "jobNo",
    },
    {
        title: "姓名",
        dataIndex: "displayName",
    },
    {
        title: "部门-岗位",
        dataIndex: "dept-post",
    },
];
export default defineComponent({
    components: {
        Teams,
    },
    emits: ["selectTeamCode"],
    setup(props, { emit }) {
        const state = reactive({
            teamsVisible: false,
            teamInfo: null,
            tableData: [],
        });

        const getTeamDetail = async id => {
            let res = await apiTeamGetInfo(id);
            if (res.status === "SUCCESS") {
                emit("selectTeamCode", res?.data?.code);
                state.teamInfo = res?.data;
                state.tableData = res?.data?.members.map((item, index) => ({
                    index: index + 1,
                    ...item,
                }));
            }
        };

        const onSelectedTeam = route => {
            if (route) {
                getTeamDetail(route?.id);
            }
        };

        return {
            ...toRefs(state),
            columns,
            fileURL,
            onSelectedTeam,
        };
    },
});
</script>
