<template>
    <a-drawer
        v-model:visible="visible"
        title="工单投产"
        :destroyOnClose="true"
        :closable="false"
        @close="handleClose"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleClose">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <BaseInfo :baseInfo="baseInfo" />
            </a-tab-pane>
            <a-tab-pane :key="2" tab="工艺信息">
                <RouteInfo @selectRouteCode="code => (routeCode = code)" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="班组信息">
                <TeamInfo @selectTeamCode="code => (teamCode = code)" />
            </a-tab-pane>
        </a-tabs>
        <div class="desc"></div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiCJWorkOrderDetail, apiCJWorkOrderPutinto } from "@/api";
import { showMessage, confirmModal } from "@/utils/common";
import BaseInfo from "./baseInfo";
import RouteInfo from "./routeInfo";
import TeamInfo from "./teamInfo";
export default defineComponent({
    props: ["produceId", "tabStatus"],
    emits: ["update:produceId"],
    components: {
        BaseInfo,
        RouteInfo,
        TeamInfo,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            activeTabKey: 1,
            produceId: null,
            routeInfo: null,
            baseInfo: null,
            routeCode: "",
            teamCode: "",
        });

        const handleClose = () => {
            emit("update:produceId");
        };

        const handleSubmit = () => {
            const { routeCode, teamCode } = state;
            if (!routeCode) return showMessage("info", "请选择工艺路线");
            if (!teamCode) return showMessage("info", "请选择班组");
            confirmModal({
                type: "warning",
                content: "投产后该工单信息不可修改/删除，是否确认投产？",
                onOk: async () => {
                    let res = await apiCJWorkOrderPutinto({
                        id: props?.produceId,
                        processCode: routeCode,
                        produceTeamCode: teamCode,
                    });
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        handleClose();
                        emit("onEmitOk");
                    }
                },
            });
        };

        const getDetailData = async id => {
            let res = await apiCJWorkOrderDetail(id);
            if (res.status === "SUCCESS") {
                const { processJson } = res?.data;
                state.baseInfo = res.data;
                state.routeInfo = processJson;
            }
        };

        watch(
            () => props.produceId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleSubmit,
            handleClose,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
