<template>
    <a-modal
        v-model:visible="visible"
        title="订单列表"
        width="1000px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-input-search
            v-model:value="produceOrderCode"
            placeholder="请输入订单号以查询"
            @search="getTabelData"
            class="search-input"
            enter-button="搜索"
        />
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :loading="loading"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: 'radio',
                getCheckboxProps: record => ({
                    disabled: record?.orderStartProductCount >= record?.quantity,
                }),
            }"
            :pagination="pagination"
            @change="paginationChange"
            :scroll="{ x: 1200 }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'key'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiProductOrderList } from "@/api";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "key",
        width: 60,
        fixed: "left",
    },
    {
        title: "生产订单号",
        dataIndex: "produceOrderCode",
        fixed: "left",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
        width: 100,
    },
    {
        title: "单位",
        dataIndex: "unit",
        width: 100,
    },
    {
        title: "生产数量",
        dataIndex: "quantity",
        width: 100,
    },
    {
        title: "下发数量",
        dataIndex: "orderStartProductCount",
    },
    {
        title: "工单数量",
        dataIndex: "workOrderCount",
    },
    {
        title: "预计开始日期",
        dataIndex: "scheduledStartDate",
    },
    {
        title: "预计完成日期",
        dataIndex: "scheduledEndDate",
    },
];

export default defineComponent({
    props: ["orderListModalVisible", "orderType"],
    emits: ["update:orderListModalVisible", "select"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            produceOrderCode: "",
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:orderListModalVisible", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                produceOrderCode,
            } = state;
            let res = await apiProductOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    produceOrderCode: produceOrderCode?.trim(),
                    status: "STARTED",
                    productionLineType: props.orderType,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择订单");
            }
            emit("select", state.selectedRows[0]);
            handleCancel();
        };

        watch(
            () => props.orderListModalVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
        };
    },
});
</script>

<style lang="less" scoped>
.search-input {
    margin-bottom: 16px;
    width: 300px;
}
</style>
