<template>
    <a-modal
        v-model:visible="visible"
        title="班组列表"
        width="1200px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-space style="margin-bottom: 20px" direction="horizontal" :size="8">
            <a-input style="width: 200px" v-model:value="codeLike" placeholder="请输入班组编码" />
            <a-input style="width: 200px" v-model:value="nameLike" placeholder="请输入班组名称" />
            <a-button type="primary" @click="handleSearch">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :loading="loading"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: 'radio',
            }"
            :pagination="pagination"
            @change="paginationChange"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiTeamList } from "@/api";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "班组编码",
        dataIndex: "code",
    },
    {
        title: "班组名称",
        dataIndex: "name",
    },
    {
        title: "班组类别",
        dataIndex: "categoryName",
    },
    {
        title: "班组人数",
        dataIndex: "memberCount",
        width: 100,
    },
    {
        title: "班组长",
        dataIndex: "leaderDisplayName",
    },
];
export default defineComponent({
    props: ["teamsVisible"],
    emits: ["update:teamsVisible", "selectedRows"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            nameLike: "",
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            codeLike: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
        });

        const reset = () => {
            state.codeLike = undefined;
            state.nameLike = undefined;
            state.pagination.current = 1;
            getTabelData();
        };

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:teamsVisible", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                nameLike,
                codeLike,
            } = state;
            let res = await apiTeamList({
                pageNum,
                pageSize,
                queryParam: {
                    codeLike: codeLike?.trim(),
                    nameLike: nameLike?.trim(),
                    enabled: true,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择班组");
            }
            emit("select", state.selectedRows[0], props.teamsVisible);
            handleCancel();
        };

        watch(
            () => props.teamsVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.pagination.current = 1;
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
            handleSearch,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.search-input {
    margin-bottom: 16px;
    width: 300px;
}
</style>
