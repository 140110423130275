<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="订单下发"
        :destroyOnClose="true"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <a-descriptions title="订单信息">
            <a-descriptions-item label="生产订单号">
                {{ orderInfo?.produceOrderCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ orderInfo?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ orderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ orderInfo?.productCode }}
            </a-descriptions-item>

            <a-descriptions-item label="规格型号">{{ orderInfo?.spec }}</a-descriptions-item>
            <a-descriptions-item label="单位">{{ orderInfo?.unit }}</a-descriptions-item>
            <a-descriptions-item label="订单数量">{{ orderInfo?.quantity }}</a-descriptions-item>
            <a-descriptions-item label="计划开始日期">
                {{ orderInfo?.scheduledStartDate }}
            </a-descriptions-item>
            <a-descriptions-item label="计划完成日期">
                {{ orderInfo?.scheduledEndDate }}
            </a-descriptions-item>
        </a-descriptions>
        <div class="desc"></div>
        <a-descriptions title="工单列表"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :scroll="{ x: 1500 }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'productName'">
                    <a-input v-model:value="record.productName" placeholder="请输入" />
                </template>
                <template v-if="column.dataIndex === 'productCode'">
                    <a-input v-model:value="record.productCode" placeholder="请输入" />
                </template>
                <template v-if="column.dataIndex === 'spec'">
                    <a-input v-model:value="record.spec" placeholder="请输入" />
                </template>
                <template v-if="column.dataIndex === 'produceTeamCode'">
                    <Select
                        v-model:value="record.produceTeamCode"
                        :options="produceTeams"
                        labelKey="value"
                        valueKey="key"
                        placeholder="请选择"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'processCode'">
                    <Select
                        v-model:value="record.processCode"
                        :options="processCodes"
                        labelKey="value"
                        valueKey="key"
                        placeholder="请选择"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'scheduledStartDate'">
                    <a-date-picker
                        v-model:value="record.scheduledStartDate"
                        valueFormat="YYYY-MM-DD"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'scheduledEndDate'">
                    <a-date-picker
                        v-model:value="record.scheduledEndDate"
                        valueFormat="YYYY-MM-DD"
                        style="width: 100%"
                    />
                </template>
                <template v-if="column.dataIndex === 'operate'">
                    <a-space direction="horizontal" :size="5">
                        <a @click="handleReset(index)">重置</a>
                        <a @click="handleDelete(index)">删除</a>
                    </a-space>
                </template>
            </template>
        </a-table>
        <div class="addicon-wrapper">
            <a-space direction="horizontal" :size="5" @click="handleAddWorkOrder">
                <plus-circle-outlined size="20" />
                <span>添加工单</span>
            </a-space>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed, onMounted, nextTick } from "vue";
import { apiProductOrderWorkOrderInfo, apiProductOrderIssue } from "@/api";
import { showMessage } from "@/utils/common";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import Select from "@/components/Select";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                产品名称
            </span>
        ),
        dataIndex: "productName",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                产品编码
            </span>
        ),
        dataIndex: "productCode",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                生产班组
            </span>
        ),
        dataIndex: "produceTeamCode",
    },
    {
        title: (
            <span>
                <span class="color">*</span>
                工艺路线
            </span>
        ),
        dataIndex: "processCode",
    },
    {
        title: "计划投产日期",
        dataIndex: "scheduledStartDate",
    },
    {
        title: "计划完工日期",
        dataIndex: "scheduledEndDate",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];

export default defineComponent({
    props: ["issueId"],
    emits: ["update:issueId"],
    components: {
        PlusCircleOutlined,
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            tableData: [],
            orderInfo: null,
            produceTeams: [],
            processCodes: [],
            baseTableItem: {
                productName: "",
                productCode: "",
                spec: "",
                produceTeamCode: undefined,
                processCode: undefined,
                scheduledStartDate: "",
                scheduledEndDate: "",
            },
        });

        const getOrderData = async id => {
            let res = await apiProductOrderWorkOrderInfo(id);
            if (res.status === "SUCCESS") {
                state.orderInfo = res.data.mesOrders;
                state.produceTeams = res.data?.basProduceTeamCodeAndNameCandidate || [];
                state.processCodes = res.data?.basProcessCodeAndNameCandidate || [];
            }
        };

        const handleAddWorkOrder = () => {
            state.tableData.push({
                ...state.baseTableItem,
            });
        };

        const handleCancel = () => {
            emit("update:issueId");
        };

        const handleSubmit = async () => {
            let isHasNull = false;
            for (let item of state.tableData) {
                if (
                    item.productName == "" ||
                    item.productCode == "" ||
                    item.produceTeamCode == undefined ||
                    item.processCode == undefined
                ) {
                    isHasNull = true;
                    break;
                }
            }
            if (isHasNull) return showMessage("info", "请完善表格数据，红色标注字段为必填项");
            let res = await apiProductOrderIssue({
                orderId: props.issueId,
                workOrderItems: state.tableData,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onEmitOk");
            }
        };

        const handleReset = index => {
            state.tableData[index] = {
                ...state.baseTableItem,
            };
        };

        const handleDelete = index => {
            state.tableData.splice(index, 1);
        };

        watch(
            () => props.issueId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getOrderData(newVal);
                    nextTick(() => {
                        document
                            .querySelectorAll(".color")
                            .forEach(elem => (elem.style.color = "red"));
                    });
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            handleAddWorkOrder,
            handleReset,
            handleDelete,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addicon-wrapper {
    margin: 16px 0;
    text-align: center;
    .ant-space {
        cursor: pointer;
    }
}
.no-enter-tips {
    color: red;
}
</style>
