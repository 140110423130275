<template>
    <a-descriptions title="">
        <a-descriptions-item label="工单号">
            {{ baseInfo?.code }}
        </a-descriptions-item>
        <a-descriptions-item label="工单类型">
            {{ baseInfo?.typeDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="工单类别">
            {{ baseInfo?.categoryName }}
        </a-descriptions-item>
        <a-descriptions-item label="生产订单号">
            {{ baseInfo?.orderCode || "未关联订单" }}
        </a-descriptions-item>
        <a-descriptions-item label="项目名称">
            {{ baseInfo?.projectName }}
        </a-descriptions-item>
        <a-descriptions-item label="项目编码">
            {{ baseInfo?.projectCode }}
        </a-descriptions-item>
        <a-descriptions-item label="产品名称">
            {{ baseInfo?.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="产品编码">
            {{ baseInfo?.productCode }}
        </a-descriptions-item>
        <a-descriptions-item label="规格型号">
            {{ baseInfo?.spec }}
        </a-descriptions-item>
        <a-descriptions-item label="批次号">
            {{ baseInfo?.productionBatchNo }}
        </a-descriptions-item>
        <a-descriptions-item label="任务数量">
            {{ baseInfo?.taskQuantity }}
        </a-descriptions-item>
        <a-descriptions-item label="单位" :span="2">
            {{ baseInfo?.taskQuantityUnit }}
        </a-descriptions-item>
        <a-descriptions-item label="计划投产日期">
            {{ baseInfo?.scheduledStartDate }}
        </a-descriptions-item>
        <a-descriptions-item label="计划完工日期">
            {{ baseInfo?.scheduledEndDate }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { defineComponent } from "vue";
import { getStatusColor } from "@/utils/common";
export default defineComponent({
    props: {
        baseInfo: {
            default: null,
        },
    },
    setup() {
        return {
            getStatusColor,
        };
    },
});
</script>
